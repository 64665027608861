.listagem_titulo {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.41px;
  line-height: 50px;
  text-align: center;
}
.listagem_subtitulo {
  font-size: 19.69px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
}

.paragrafo {
  font-size: 17px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: justify;
}

.paragrafo a {
  text-decoration: underline;
}
.BannerInt {
  background-position: center 69px;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  @media (max-width: 767px) {
    .listagem_titulo {
      font-size: 28px;
      line-height: 35px;
    }
    .listagem_subtitulo {
      font-size: 13.78px;
      line-height: 18px;
    }
  }
}
