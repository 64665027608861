.box {
  left: 0;
  border-radius: 20px 20px 0 0;
  transform: translateY(0px);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  padding-bottom: 25px;
  padding-top: 35px;
  height: 100%;
}
.box.height {
  z-index: 1035;
  padding-bottom: 75px;
  max-height: calc(100%);
  height: auto;
}

.retangle {
  overflow-y: overlay;
  max-height: 100%;
  padding-bottom: 80px;
}
.retangle > ul {
  padding: 0 12px;
}

.retangle > ul > li {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 50px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}
.retangle > ul > li > a {
  width: 215px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.retangle > ul > li > a > svg {
  margin-right: 10px;
  height: 20px;
  width: auto;
}

.zone {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.Titulo {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.21px;
  line-height: 25px;
  padding-top: 20px;
}

.local {
  color: #a8a8a8;
  font-size: 16px;
  letter-spacing: 0.34px;
  line-height: 20px;
}

.number {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.34px;
  line-height: 20px;
}
.btn {
  height: 45px;
  border-radius: 22.5px;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  text-transform: uppercase;
}
.btn::before {
  border-radius: 22.5px;
  width: 100%;
  height: 45px;
}

.btn svg {
  margin-right: 10px;
}
.btnRedes {
  height: 45px;
  border-radius: 22.5px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  text-transform: uppercase;
}
.btnRedes img {
  margin-right: 10px;
}

.divBtn {
  width: 160px !important;
  flex: 0 0 auto !important;
}

.BoderBottom {
  border-bottom: 2px solid #f4f4f4;
  padding-bottom: 12px;
  padding-top: 12px;
}

.close {
  top: 7px;
  position: absolute;
  right: 13px;
  cursor: pointer;
}
