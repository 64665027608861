.cantoSuperiorImagem {
  z-index: 5;
  position: absolute;
  width: fit-content !important;
  top: 25px;
  right: 0;
  border-top-left-radius: 13.5px;
  border-bottom-left-radius: 13.5px;
  overflow: hidden;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 25px;
}
.badge {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 21px;
  padding: 2px 10px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.cantoSuperiorImagemBanner {
  z-index: 5;
  width: initial !important;

  height: 27px;
  text-align: center;
}

.badgeV {
  background-color: #b00034;
}
.badgeR {
  background-color: #7448a6;
}
.badgeP {
  background-color: #ee7c10;
}

.badgeBanner {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.3px;
  line-height: 27px;
  padding: 2px 10px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 13.5px;
  overflow: hidden;
}

.cantoSuperiorImagemTopo {
  z-index: 5;
  position: absolute;
  width: fit-content !important;
  top: 10px;
  border-radius: 13.5px;
  overflow: hidden;
  height: 25px;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.cantoSuperiorImagemBottom {
  z-index: 7;
  position: absolute;
  width: -moz-fit-content !important;
  width: fit-content !important;
  bottom: 0px;
  border-radius: 13.5px;
  overflow: hidden;
  height: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 !important;
}

.cantoSuperiorImagemConer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  display: inline-block;
  border-top-left-radius: 15px;
  overflow: hidden;
  z-index: 5;
}
.cantoSuperiorImagemConer > div {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 100px 100px 0 0;
}
.ConerbadgeV {
  border-color: #b00034 transparent transparent transparent;
}
.ConerbadgeR {
  border-color: #7448a6 transparent transparent transparent;
}
.ConerbadgeP {
  border-color: #ee7c10 transparent transparent transparent;
}

.cantoSuperiorImagemConer > div > label {
  position: relative;
  top: -81px;
  left: -11px;
  text-decoration: none;
  word-wrap: normal;
  min-width: 80px;
  color: #fff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  font-size: 10px;
  line-height: 15px;
}

@media (max-width: 767px) {
  .badge {
    font-size: 10px;
  }
  .cantoSuperiorImagemBottom {
    height: 20px;
  }
}
