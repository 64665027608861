.section {
  position: fixed;
  bottom: 0;
  width: 100%;

  z-index: 11;
  bottom: -100px;
  height: 144px;
  -webkit-box-shadow: 0 0 5px rgb(40 40 40 / 25%);
  -ms-box-shadow: 0 0 5px rgba(40, 40, 40, 0.25);
  -o-box-shadow: 0 0 5px rgba(40, 40, 40, 0.25);
  box-shadow: 0 0 5px rgb(40 40 40 / 25%);

  transition: transform 0.3s;
  border-radius: 20px 20px 0 0;
  padding: 10px 0;
  transition: bottom 0.3s ease 0s;
}
.section.open {
  bottom: 0px;
}
.navbarScrollCustom {
  transform: translateY(-110px) !important;
  transition: transform 0.3s;
}
.sectionBig {
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 10%;
  z-index: 20;
  border-radius: 20px 20px 0 0;
  transform: translateY(0px) !important;
}
.zone {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.sectonCarate {
  overflow-y: overlay;
  max-height: 79%;
}
.arrow {
  position: absolute;
  right: 0;
  height: 40px;
  width: 40px !important;
  top: -10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.titulo {
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0.3px;
  line-height: 28px;
  margin-bottom: 5px;
}
.arrow svg {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  -ms-transition: -ms-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}
.section.open .arrow svg {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.sectionBig .arrow svg {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.btnComparador {
  height: 45px;
  border-radius: 22.5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  width: 100%;
  text-transform: uppercase;
}

.lineCarat,
.lineCarat div {
  height: 42px;
}
.lineCarat > div > div > div {
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 24%;
  max-width: 24%;
}
.lineCarat > div > div > div:nth-child(1) {
  font-weight: bold;
  width: 9%;
  max-width: 9%;
}
.lineCarat:last-child > div > div > div {
  border-bottom: 0px solid #f9f9f9;
}
.sectionBig .sectionBox {
  -webkit-box-shadow: 0 0 5px rgb(40 40 40 / 25%);
  -ms-box-shadow: 0 0 5px rgba(40, 40, 40, 0.25);
  -o-box-shadow: 0 0 5px rgba(40, 40, 40, 0.25);
  box-shadow: 0 0 5px rgb(40 40 40 / 25%);
  position: relative;
  padding: 15px 0;
}

@media (max-width: 1400px) {
  .lineCarat > div > div > div:nth-child(1) {
    width: 11%;
    max-width: 11%;
  }
  .lineCarat > div > div > div {
    width: 29.6666%;
    max-width: 29.6666%;
  }
}

@media (max-width: 1200px) {
  .lineCarat > div > div > div:nth-child(1) {
    max-width: 12.5%;
    width: 12.5%;
  }
  .lineCarat > div > div > div {
    width: 32.166666%;
    max-width: 32.166666%;
  }
  .lineCarat > div > div > div:nth-child(4) {
    width: 23.166666%;
    max-width: 23.166666%;
  }
}

@media (max-width: 991px) {
  .lineCarat > div > div > div:nth-child(1) {
    max-width: 18.1%;
    width: 18.1%;
  }
  .lineCarat > div > div > div {
    width: 47.166666%;
    max-width: 47.166666%;
  }
  .lineCarat > div > div > div:nth-child(3) {
    width: 34.7333333%;
    max-width: 34.7333333%;
  }

  .navbarScrollCustomComparar {
    transform: translateY(-70px) !important;
    -webkit-transition: transform 0.3s, bottom 0.3s ease 0s;
    transition: transform 0.3s, bottom 0.3s ease 0s;
  }
  .navbarScrollCustom {
    transform: translateY(-175px) !important;
    -webkit-transition: transform 0.3s, bottom 0.3s ease 0s;
    transition: transform 0.3s, bottom 0.3s ease 0s;
  }
  .section {
    border-radius: 25px 25px 0 0;
  }
  .sectionBig {
    border-radius: 25px 25px 0 0;
  }
}

@media (max-width: 767px) {
  .titulo {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.22px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .lineCarat > div > div > div:nth-child(1) {
    max-width: 100%;
    width: 100%;
    flex: 0 0 auto;
  }
  .lineCarat > div > div > div {
    width: 50%;
    max-width: 50%;
  }
  .lineCarat > div > div > div:nth-child(3) {
    width: 50%;
    max-width: 50%;
  }
  .lineCarat > div > div > div {
    justify-content: center;
    text-align: center;
  }
  .lineCarat {
    height: 60px;
  }
  .lineCarat div {
    height: 30px;
  }
  .sectionBig {
    width: 100%;
    top: 0%;
    z-index: 1102;
  }
  .section {
    height: 135px;
    bottom: -98px;
  }
  .section.open {
    bottom: -12px;
  }
  .navbarScrollCustom {
    transform: translateY(0);
    transition: transform 0.3s;
  }

  .sectionBig.open {
    transform: translateY(0px) !important;
  }
  .sectionBig .sectionBox {
    height: 260px;
    padding-bottom: 20px;
  }
  .sectonCarate {
    overflow-y: scroll;
    height: calc(100% - 260px);
  }
}
@media (max-width: 575px) {
  .sectionBig .sectionBox {
    height: calc(40vw + 50px);
  }
  .sectonCarate {
    height: calc(100% - calc(40vw + 50px));
  }
  .lineCarat > div > div > div {
    line-height: 12px;
    text-align: center;
  }
}
@media (max-width: 380px) {
  .sectionBig .sectionBox {
    height: calc(50vw + 42px);
  }
  .sectonCarate {
    height: calc(100% - calc(50vw + 42px));
  }
  .btnComparador {
    font-size: 10px;
    padding: 0 5px;
  }
  .btnComparador svg {
    margin-right: 5px !important;
  }
}
