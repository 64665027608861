.foto {
  position: relative;
  height: 0;
  padding-bottom: 19%;
}
.foto img {
  object-fit: cover;
  visibility: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  left: 0;
  top: 0;
  border-radius: 14px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
}

.marca {
  font-size: 16px;
  letter-spacing: 0.34px;
  line-height: 20px;
  font-weight: 500;
  max-height: 39px;
  overflow: hidden;
}
.marca span {
  font-weight: 800;
}
.preco {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.16px;
  line-height: 20px;
}
.remove {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.remove svg {
  margin-right: 5px;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
}

@media (max-width: 991px) {
  .marca {
    max-height: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .marca {
    font-size: 14px;
    line-height: 18px;
  }
  .preco {
    font-size: 14px;
    line-height: 18px;
  }
  .foto {
    padding-bottom: 50.5%;
    margin-bottom: 15px !important;
  }
  .foto img {
    width: 85%;
  }
  .dBlockImage {
    display: block;
  }
  .dNoneImg {
    display: none;
  }
  .boxP {
    padding-left: 30px !important;
  }
  .removeP {
    position: absolute;
    margin-left: -30px;
    font-size: 10px;
  }
  .remove svg {
    margin-right: 0px;
  }
}
@media (max-width: 400px) {
  .removeP {
    font-size: 8px;
  }
}
