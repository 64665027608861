.box {
  transition: all 0.2s ease-in-out;
}

.box:hover,
.box:focus {
  transform: scale(1.05);
}

.boxSolo {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
}

.ImagemDestaque {
  border-radius: 32.4px;
  box-shadow: 0 12px 19px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  z-index: 5;
}

.Imagem {
  overflow: hidden;
  border-radius: 32.4px;
}
.Imagem img {
  overflow: hidden;
  border-radius: 32.4px;
}

.listagem_info_destaques {
  position: relative;
  width: 80%;
  margin: auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 45.6px;
  border-bottom-left-radius: 45.6px;
  z-index: -1;
  transition: opacity 800ms ease-in;
  overflow: hidden;
  padding-right: 2vw;
  padding-left: 2vw;
  padding-bottom: 2vw;
  z-index: 2;
  border-top: none !important;
}

.listagem_solo_destaques {
  position: relative;
  border-top-left-radius: 0;
  border-top-right-radius: 45.6px;
  border-bottom-right-radius: 45.6px;
  border-bottom-left-radius: 0;
  padding: 30px !important;
  z-index: 0 !important;
  transition: opacity 800ms ease-in;
  overflow: hidden;
  height: 100%;
  border-left: none !important;
}

.boxPreco span {
  font-size: clamp(28.04px, 2.5vw, 45.64px);
}
.boxPreco {
  font-size: clamp(18.12px, 1.8vw, 35.52px);
  font-weight: 900;
  letter-spacing: 0.36px;
  line-height: 45px;
}
.nocampanha {
  font-size: clamp(12px, 1vw, 16.8px);
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 21px;
  height: 21px;
}

.nocampanhaColor {
  color: #999999;
}
.campanha {
  color: #ee7c10;
  font-weight: 800;
  text-transform: uppercase;
}
.texto_listagem_marca {
  font-size: 38.69px;
  font-weight: 800;
  letter-spacing: 0.4px;
  line-height: 49px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.texto_listagem_modelo {
  font-size: 23.71px;
  letter-spacing: 0.37px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 20px;
}
.listagem_specs_item {
  color: #ffffff;
  font-size: 15.6px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 20px;
  padding: 15px;
}
.listagem_specs_item svg {
  margin-right: 10px;
}

.listagem_specs_item_solo {
  font-size: 15.6px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 20px;
  padding: 15px;
  text-align: center;
  margin-right: 20px;
}
.listagem_specs_item_solo:last-child {
  margin-right: 0px;
}
.listagem_specs_item_solo svg {
  margin-bottom: 10px;
}
.listagem_specs_info_solo {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.listagem_specs_info_solo > div:first-child {
  color: #a8a8a8;
}

.listagem_specs_info_solo > div:last-child {
  font-weight: 600;
}
.ImagemDestaque .listagem_specs {
  border-radius: 0px 0px 32.4px 32.4px;
}
.texto_listagem_marca_solo {
  font-size: 42.56px;
  font-weight: 800;
  letter-spacing: 0.44px;
  line-height: 54px;
}
.texto_listagem_modelo_solo {
  font-size: 26.08px;
  letter-spacing: 0.41px;
  line-height: 33px;
}
/* The element to apply the animation to */
@media (max-width: 991px) {
  .boxPreco span {
    font-size: 38.64px;
  }
  .boxPreco {
    font-size: 28.52px;
    line-height: 38px;
  }
  .nocampanha {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .box:hover,
  .box:focus {
    transform: scale(1);
  }
  .Imagem {
    border-radius: 0;
  }
  .Imagem img {
    border-radius: 0;
  }
  .ImagemDestaque .listagem_specs {
    border-radius: 0px;
  }
  .listagem_info_destaques {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 575px) {
  .ImagemDestaque .listagem_specs {
    border-radius: 0px;
  }
  .listagem_info_destaques {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .boxPreco span {
    font-size: 35.64px;
  }
  .boxPreco {
    font-size: 25.52px;
    line-height: 38px;
  }
  .nocampanha {
    font-size: 12.8px;
  }
  .texto_listagem_marca {
    font-size: 30.69px;
    line-height: 30px;
  }
  .texto_listagem_modelo {
    font-size: 16.71px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .listagem_specs_item {
    padding: 15px 9px;
  }
}

/* Destaques Banner */
.imgbEqualBanner {
  padding-bottom: 55%;
  position: relative;
}
.BoxBanner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.82) 100%
  );
  padding-bottom: 35px;
}
.tituloBanner {
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0.17px;
  line-height: 32px;
  text-align: center;
}
.MarcaBanner {
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0.35px;
  line-height: 42px;
  text-align: center;
}
.MarcaBanner span {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.31px;
  line-height: 42px;
}
.flexInfoBanner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnCompare {
  stroke: #ffffff;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
}

.listagem_specs_item_banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.listagem_specs_item_banner > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.listagem_specs_item_banner > div:last-child > div:first-child {
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}
.listagem_specs_item_banner > div:last-child > div:last-child {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}
.divCompare {
  padding: 0 15px;
  padding-top: 10px;
}
.nocampanhaBanner {
  color: #ffffff;
}
.flexPreco {
  display: flex;
  align-items: baseline;
}
.flexPreco span {
  margin-right: 5px;
}

.imgbBanner {
  overflow: hidden;
}
.imgbBanner img {
  animation: scaling 40s infinite linear;
  -webkit-animation: scaling 40s infinite linear;
}
@keyframes scaling {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

.Mais {
  height: 32px;
  width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  z-index: 7;
  position: relative;
}

.btnDireccoes {
  color: #ffffff;
  border: none;
  border-radius: 22.5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  height: 45px;
  width: 255px;
  line-height: 45px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnDireccoes::before {
  border-radius: 22.5px;
  height: 45px;
  width: 255px;
}
.btnDireccoes img {
  margin-right: 10px;
  height: 21px;
  width: 21px;
}
.Row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 1400px) {
  .boxSolo {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 1200px) {
  .listagem_specs_item {
    font-size: 12px;
  }

  .listagem_solo_destaques {
    padding: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 991px) {
  .listagem_specs_item {
    font-size: 15.6px;
  }
  .listagem_solo_destaques {
    width: 80% !important;
    margin: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 45.6px;
    border-bottom-left-radius: 45.6px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media (max-width: 767px) {
  .noPaddingXs {
    padding: 0 !important;
  }

  .boxSolo {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .BoxBanner {
    position: relative;
    background-color: rgba(13, 13, 13, 1);
    box-shadow: 0px -15px 20px 10px rgb(13 13 13 / 97%);
  }
  .listagem_specs_item_banner {
    margin: auto;
    margin-left: 0;
    margin-bottom: 20px;
    justify-content: flex-start;
  }
  .flexInfoBanner {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .tituloBanner {
    display: none;
  }
  .MarcaBanner {
    line-height: 32px;
    margin-bottom: 20px;
  }
  .flexInfoBanner {
    padding: 0px 85px;
  }
  .flexInfoBanner a {
    width: 33.33333%;
  }
  .flexInfoBanner a:nth-child(4) {
    padding: 0 12px;
    width: 75%;
  }
  .flexInfoBanner > div {
    width: 25%;
    padding: 0 12px;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 660px) {
  .flexInfoBanner {
    padding: 0px 12px;
  }
}

@media (max-width: 574px) {
  .listagem_solo_destaques {
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 15px !important;
  }

  .boxSolo {
    padding-left: 12px;
    padding-right: 12px;
  }
  .listagem_specs_item {
    font-size: 4.8vw;
  }
  .listagem_specs_item_banner > div:last-child > div:first-child {
    font-size: 10px;
    line-height: 13px;
  }
  .listagem_specs_item_banner > div:last-child > div:last-child {
    font-size: 12px;
    line-height: 15px;
  }
  .flexInfoBanner {
    padding: 0px;
  }
  .btnDireccoes {
    width: 300px;
  }
  .btnDireccoes::before {
    width: 300px;
  }
  .texto_listagem_marca_solo {
    font-size: 38.69px;
    letter-spacing: 0.4px;
    line-height: 49px;
  }
  .texto_listagem_modelo_solo {
    font-size: 23.71px;
    letter-spacing: 0.37px;
    line-height: 30px;
  }
}
@media (max-width: 425px) {
  .listagem_specs_item_solo {
    margin-right: 15px;
  }
}

@media (max-width: 400px) {
  .listagem_specs_item_banner {
    margin-right: 0;
  }
  .listagem_specs_item_solo {
    margin-right: 5px;
  }
}
