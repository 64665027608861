.btnPartilharFicha {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.btnPartilharFicha img {
  margin-right: 3px;
}
.btnComparar {
  height: 34px;
  border-radius: 17px;
  margin: auto;
  padding: 0px 15px;
}

.btn_ficha,
.btn_ficha a {
  text-transform: uppercase;
  align-items: center;
  margin: auto;
  justify-content: center;
  width: 100%;
  height: 45px;
  outline: 0;
  border: none;
  border-radius: 22.5px;
  color: #ffffff;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  display: flex;
  padding: 0 20px;
}
.btn_ficha a:hover {
  color: #ffffff;
}
.btn_ficha::before {
  border-radius: 22.5px;
  width: 100%;
  height: 45px;
}

.btn_pedir {
  text-transform: uppercase;
  align-items: center;
  margin: auto;
  justify-content: center;
  width: 100%;
  height: 45px;
  outline: 0;
  border-radius: 22.5px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  display: flex;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
}

.texto_listagem_marca {
  font-size: 28.1px;
  font-weight: 800;
  letter-spacing: 0.29px;
  line-height: 35px;
}

.texto_listagem_modelo {
  font-size: 19px;
  letter-spacing: 0.3px;
  line-height: 24px;
}

.nocampanha,
.nocampanha a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 18px;
}
.campanha {
  color: #ee7c10;
  font-weight: 800;
  text-transform: uppercase;
}

.boxPreco span {
  font-size: 40.54px;
  margin-right: 5px;
}
.boxPreco {
  font-size: 29.6px;
  font-weight: 900;
  letter-spacing: 0.3px;
  line-height: 38px;
  display: flex;
  align-items: baseline;
}
.boxTop {
  padding: 30px 40px 20px;
}
.boxTop2 {
  padding: 20px 0px 20px;
}
.moreInfoFicha {
  padding: 30px;
}
.heightBoxTop {
  height: 15px;
}
.a:hover {
  color: #999999;
}
.TextFinanceiro > div span {
  color: #a8a8a8;
}
.TextFinanceiro > div span {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.TextFinanceiro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.TextFinanceiro > div {
  margin-bottom: 15px;
}
.boxTopFinanceiro {
  padding-top: 0px !important;
  padding-bottom: 15px !important;
}

.texto_listagem_marca_top span {
  font-weight: 800;
}
.texto_listagem_marca_top {
  font-size: 23px;
  letter-spacing: 0.24px;
  line-height: 29px;
}

@media (max-width: 1400px) {
  .btnComparar {
    flex-direction: column;
    height: 50px;
    width: 100%;
    font-size: 10px;
    padding-top: 5px;
  }
  .btnComparar svg {
    margin-right: 0 !important;
  }
  .boxTop {
    padding: 30px 20px 20px;
  }

  .texto_listagem_marca {
    font-size: 25.29px;
    line-height: 32px;
  }
  .texto_listagem_modelo {
    font-size: 17.1px;
    line-height: 21px;
  }
  .nocampanha,
  .nocampanha a {
    font-size: 12px;
    line-height: 14px;
  }

  .boxPreco span {
    font-size: 36.64px;
  }
  .boxPreco {
    font-size: 26.64px;
    line-height: 34px;
  }
  .TextFinanceiro > div span {
    font-size: 14.4px;
    line-height: 18px;
  }
}
@media (max-width: 1200px) {
  .btnPartilharFicha {
    font-size: 10px;
  }
}

@media (max-width: 991px) {
  .btnPartilharFicha {
    font-size: 12px;
  }

  .TextFinanceiro > div span {
    font-size: 16px;
    line-height: 20px;
  }
  .texto_listagem_marca {
    font-size: 28.1px;
    line-height: 35px;
  }

  .texto_listagem_modelo {
    font-size: 19px;
    line-height: 24px;
  }

  .nocampanha,
  .nocampanha a {
    font-size: 14px;
    line-height: 18px;
  }

  .boxPreco span {
    font-size: 40.54px;
  }
  .boxPreco {
    font-size: 29.6px;
    line-height: 38px;
  }

  .nocampanha1,
  .nocampanha1 a {
    font-size: 12px;
  }
  .boxPreco1 span {
    font-size: 25.54px;
    letter-spacing: 0.19px;
    line-height: 33px;
  }
  .boxPreco1 {
    font-size: 18.65px;
    letter-spacing: 0.19px;
    line-height: 24px;
  }
  .btn_ficha,
  .btn_ficha a {
    padding: 0 5px;
    margin-bottom: 15px;
  }
  .btn_pedir {
    padding: 0 5px;
    margin-bottom: 15px;
  }
  .btnPartilharFicha {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .btnComparar {
    flex-direction: row;
    height: 34px;
    width: 100%;
    font-size: 10px;
    justify-content: center;
    padding-top: 0;
  }
  .btnComparar svg {
    margin-right: 5px !important;
  }

  .texto_listagem_modelo {
    margin-bottom: 20px;
  }
  .boxTopFinanceiro {
    padding-top: 15px !important;
  }
  .boxTop {
    padding: 25px 20px 10px;
  }
  .moreInfoFicha {
    padding: 20px 5px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .btn_ficha,
  .btn_ficha a {
    margin-bottom: 15px;
    padding: 0 15px;
  }
  .btn_pedir {
    margin-bottom: 15px;
    padding: 0 15px;
  }

  .btnComparar {
    margin: auto;
  }
}

@media (max-width: 574px) {
  .boxTop {
    padding: 20px 15px 10px;
  }
  .moreInfoFicha {
    padding: 15px 0;
  }
  .btn_ficha,
  .btn_ficha a {
    margin-bottom: 15px;
  }
  .btn_pedir {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .boxPreco {
    font-size: 5.5vw;
    line-height: 7.5vw;
  }
  .boxPreco span {
    font-size: 7.5vw;
  }
  .nocampanha,
  .nocampanha a {
    font-size: 3.2vw;
    line-height: 3.2vw;
  }
  .texto_listagem_marca {
    font-size: 20.1px;
    line-height: 25px;
  }
  .texto_listagem_modelo {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 15px;
  }
}
@media (max-width: 420px) {

  .btnComparar {
    flex-direction: column;
    height: 50px;
    width: 100%;
    font-size: 9px;  
    padding: 1vw;
    padding-top: 5px;
  }
  .btnComparar svg {
    margin-right: 0 !important;
  }
}

