.banner {
  height: 0;
  padding-bottom: 36.4%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 50%;
  position: relative;
}
.banner a {
  height: 100%;
  display: block;
}

.banner img,
.banner a img {
  height: 100%;
  left: 50%;
  margin: 0;
  object-fit: cover;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.bannerVideo {
  height: 0;
  padding-bottom: 36.4%;
  position: relative;
}

.bannerVideo iframe {
  height: 100%;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .bannerVideo {
    padding-bottom: 55.4%;
  }
}

@media only screen and (max-width: 991px) {
  .banner {
    height: 0;
    padding-bottom: 96%;
  }

}
@media only screen and (max-width: 767px) {
  .bannerVideo {
    padding-bottom: 70%;
  }
}
