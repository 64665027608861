.padding2 {
  --bs-gutter-x: 1em;
}

.texto {
  font-size: 30.69px;
  font-weight: 800;
  letter-spacing: 0.24px;
  line-height: 40px;
  text-align: center;
}

.sphere {
  height: 161px;
  width: 161px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -80px;
  margin-bottom: 15px;
}

.paragrafo {
  font-size: 17px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: justify;
  height: 219px;
  overflow: hidden;
}
.a {
  text-decoration: underline;
  margin-left: 5px;
}
.rectangle {
  position: relative;
  border-radius: 30px;
  margin-bottom: 50px;
  transition: all 0.2s ease-in-out;
  padding: 0 30px;
  margin-top: 90px;
  padding-bottom: 30px;
  cursor: pointer;
}
.rectangle:hover,
.rectangle:focus {
  transform: scale(1.05);
}
.h80 {
  height: 95px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
}

@media (max-width: 991px) {
  .padding2 {
    --bs-gutter-x: 1.5em;
  }

  .texto {
    font-size: 20.69px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .padding2 {
    --bs-gutter-x: 1.5em;
  }
  .h80 {
    height: auto;
  }
  .rectangle:hover,
  .rectangle:focus {
    transform: scale(1);
  }
}

@media (max-width: 575px) {
  .texto {
    font-size: 16.11px;
    letter-spacing: 0.12px;
    line-height: 21.5px;
  }
}
