.imgbEqual {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 70%;
}
.imgbEqual img {
  object-fit: cover;
  visibility: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  left: 0;
  top: 0;
}
.listagem_info {
  position: relative;
  border-radius: 38px;
  padding: 20px;
  padding-bottom: 40px;
  transition: all 0.2s ease-in-out;
}
.listagem_info:hover,
.listagem_info:focus {
  transform: scale(1.05);
}

.listagem_info_box {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.texto_listagem_marca {
  font-size: 31px;
  font-weight: 800;
  letter-spacing: 0.32px;
  line-height: 39px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.texto_listagem_modelo {
  font-size: 20px;
  letter-spacing: 0.32px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 20px;
}
.listagem_specs_item {
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.listagem_specs_item svg {
  margin-right: 10px;
}
.listagem_specs {
  padding-top: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #292929 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.nocampanha {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 18px;
  height: 18px;
}
.nocampanhaColor {
  color: #999999;
}

.campanha {
  color: #ee7c10;
  font-weight: 800;
  text-transform: uppercase;
}

.boxPreco span {
  font-size: 40.54px;
}
.boxPreco {
  font-size: 27.6px;
  font-weight: 900;
  letter-spacing: 0.3px;
  line-height: 38px;
}

.btnCarregarMais {
  text-transform: uppercase;
  border: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 255px;
  border-radius: 22.5px;
  cursor: pointer;
  height: 45px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
}

.Mais {
  height: 60px;
  width: 75px;
  border-radius: 40px 0 38px 0;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 6;
}
.Mais.MaisOpen {
  position: relative;
}

.noMargin {
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (max-width: 1599px) {
  .boxPreco {
    font-size: 23.6px;
  }
  .boxPreco span {
    font-size: 34.54px;
  }
}
@media (max-width: 991px) {
  .listagem_specs_item {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .listagem_info:hover,
  .listagem_info:focus {
    transform: scale(1);
  }
}

@media (max-width: 575px) {
  .listagem_specs_item {
    font-size: 3.8vw;
  }
  .relacionados .listagem_specs_item {
    font-size: 3vw;
  }
  .listagem_info_box {
    padding-left: 32px;
    padding-right: 32px;
  }
  .listagem_info:hover,
  .listagem_info:focus {
    transform: scale(1);
  }
  .Mais {
    height: 45px;
    width: 60px;
  }
  .texto_listagem_marca {
    font-size: 28px;
    letter-spacing: 0.26px;
    line-height: 31px;
  }
  .texto_listagem_modelo {
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 22px;
  }

  .nocampanha {
    font-size: 11.2px;
    letter-spacing: 0.24px;
    line-height: 14px;
  }
  .boxPreco {
    font-size: 23.68px;
    letter-spacing: 0.24px;
    line-height: 30px;
  }
  .boxPreco span {
    font-size: 32.43px;
    letter-spacing: 0.24px;
    line-height: 40px;
  }
  .listagem_info {
    padding: 0px;
    overflow: hidden;
  }

  .noMargin {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
