.box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  z-index: 5;
}
.boxDestaque {
  background-color: #242424;
  color: #fff;
  fill: #fff;
  stroke: #fff;
  width: 235px;
  border-radius: 10px;
  height: 200px;
  left: auto;
  right: 0;
}
.boxDestaque a {
  color: #fff;
  fill: #fff;
  stroke: #fff;
}


.rectangle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
  width: 85%;
  cursor: pointer;
  height: 100%;
}
.rectangle:last-child {
  border-bottom: 0px solid #f0f0f0;
}
.rectangle2 {
  width: 70%;
}
.rectangle2:first-child {
  border-top: 1px solid #f0f0f0;
}

.rectangleBanner {
  border-bottom: 1px solid #333333;
}

.rectangle div {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;

  display: flex;
  align-items: center;
  width: 100px;
  text-transform: uppercase;
}
.opcoesEnter {
  opacity: 0;
  height: 0;
}
.opcoesEnterActive {
  opacity: 1;
  height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.opcoesExit {
  opacity: 1;
}
.opcoesExitActive {
  opacity: 0;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.opcoesBannerEnterActive {
  opacity: 1;
  height: 200px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .boxDestaque {
    width: 362px;
    right: 12px;
  }
}

@media (max-width: 574px) {
  .boxDestaque {
    width: 96vw;
    bottom: -11px;
  }
}
