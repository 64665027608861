.box {
  border-radius: 38px;
  height: 100%;
}

.navbarScrollCustom {
  transform: translateY(-95px);
  transition: transform 0.3s;
}
.rectangle {
  padding: 10px 0px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 15;
  top: 95px;
  height: fit-content;
  -ms-box-shadow: 0 0 5px rgba(40, 40, 40, 0.25);
  -o-box-shadow: 0 0 5px rgba(40, 40, 40, 0.25);
  box-shadow: 0 0 5px rgb(40 40 40 / 25%);
  transition: transform 0.2s;
  border-radius: 0 0;
}

@media (max-width: 991px) {
  .rectangle {
    top: 85px;
  }
}
