.boxPadding {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
}
@media (max-width: 574px) {
  .boxPadding {
    padding-left: 12px;
    padding-right: 12px;
  }
}
