.rectangle {
  height: 46px;
  display: flex;
  width: 100%;
  padding: 0px 60px;
}

.box {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 16px;
  border-radius: 12px;
  padding: 5px 15px;
}

.arrow {
  display: none;
}
.values {
  display: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 18px;
}

@media (max-width: 767px) {
  .arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .box {
    padding: 0px 11px;
    border-radius: 14.5px;
    min-height: 26px;
    min-width: 47px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .rectangle {
    padding: 0px 37px;
  }
  .values {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 8px;
    margin-bottom: -11px;
  }
}
