.retangle {
  width: 100%;
  height: 0;
  display: flex;
  justify-content: flex-end;
}

.position {
  right: 15px;
  position: fixed;
  bottom: 135px;
  border-radius: 50px;
  z-index: 1001;
  transition: max-height 1s cubic-bezier(0.19, 0.84, 0.55, 0.82) 0s,
    bottom 0.3s ease 0s, opacity 0.3s ease-in-out;
  height: 0;
  max-height: 0;
  width: 93px;
  overflow: visible;
  padding-bottom: 0;
  opacity: 0;
}

.position > div:first-child {
  display: none;
  -webkit-animation-name: fadeInte;
  -webkit-animation-duration: 0.5s;
  animation-name: fadeInte;
  animation-duration: 0.5s;
  opacity: 0;
}
@-webkit-keyframes fadeInte {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInte {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
.position.open > div:first-child {
  display: block;
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.position.open {
  opacity: 1;
  height: auto;
  max-height: 600px;
  padding-bottom: 90px;
  overflow: hidden;

  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.position.bottom {
  bottom: 200px;
}
.dot {
  right: 15px;
  position: fixed;
  bottom: 140px;
  margin-right: 6px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-direction: column;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 13px;
  text-align: center;
  z-index: 1002;
  cursor: pointer;

  transition: max-height 1s cubic-bezier(0.19, 0.84, 0.55, 0.82) 0s,
    bottom 0.3s ease 0s, opacity 0.3s ease-in-out;

  opacity: 0.5;
}
.dot:hover {
  opacity: 1;
}
.dot::before {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.dot.bottom {
  bottom: 206px;
}
.position.open + .dot {
  opacity: 1;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a8a8a8;
  font-size: 10.14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 10px;
  cursor: pointer;
}
.box svg,
.box img {
  margin-bottom: 10px;
}
.navbarScrollCustom {
  bottom: 275px !important;
}
.navbarScrollCustomDot {
  bottom: 281px !important;
}

@media only screen and (max-width: 991px) {
  .section {
    display: none;
  }
}
