.box {
  width: 100vw;
  height: 100vh;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.black {
  background: #000000;
}
.white {
  background: #ffffff;
}
.img {
  animation: zoomin 2s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

@keyframes zoomin {
  0% {
    height: 40px;
  }
  50% {
    height: 50px;
  }
}
