.btn {
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 600 !important;
  line-height: 29px !important;
  padding: 1px 10px !important;
  background-color: rgba(190, 190, 190, 0.6) !important;
  border-radius: 22px !important;
  text-decoration: none !important;
}
.p {
  font-size: 14px !important;
  color: #fff !important;
}

.Link {
  font-size: 14px !important;
  color: #fff !important;
  text-decoration: underline;
}
.Link:hover {
    text-decoration: none;
  }